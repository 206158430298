import {Alert, ButtonGroup} from "@mui/material";
import {loadedGames} from "./gameSlice";
import {pending} from "../general/connectionSlice";
import {useAppSelector} from "../../app/hooks";
import React from "react";
import ScoreButton from "./ScoreButton";
import {getCompetitionToken} from "../../Util";

function GamesList(){
    const games = useAppSelector(loadedGames);
    const isPending = useAppSelector(pending);

    const token = getCompetitionToken();

    let point;
    let headerFunc: (index: number) => string;

    if (token && token.includes("_")){
        point = "doelpunt";
        headerFunc = (_: number) => `Veld ${parseInt(token.split("_")[2]) + 1}`;
    } else {
        point = "set";
        headerFunc = (index: number) => getDateString(games.games[index].startsAt);
    }

    return <div>
        <Alert icon={false} severity={"info"}>
            <ul>
                <li>Klik op de atleten om een {point} toe te voegen</li>
                <li>Klik op het aantal (getal) om een {point} te verwijderen</li>
            </ul>
        </Alert>
        {games.games.map((game, index) =>
            <div key={game.id} style={{marginBottom: '0.5em'}}>
                {index === 0 || headerFunc(index) !== headerFunc(index - 1) ? <h3 style={{marginBottom: 0}}>{headerFunc(index)}</h3> : null}

                <ButtonGroup disabled={isPending} fullWidth variant="outlined" aria-label="outlined button group">
                    <ScoreButton game={game} isHome={true}/>
                    <ScoreButton game={game} isHome={false}/>
                </ButtonGroup>
            </div>
        )
        }
    </div>
}

function getDateString(datetime: string): string{
    return new Date(datetime).toLocaleDateString()
}

export default GamesList;
