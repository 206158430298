import {Button} from "@mui/material";
import {draw, loadedGames, undraw} from "./gameSlice";
import {pending} from "../general/connectionSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import React from "react";
import GamesList from "./gamesList";

function GameSection(){
    const dispatch = useAppDispatch();
    const games = useAppSelector(loadedGames);
    const isPending = useAppSelector(pending);
    const hasUnfinishedGames = games.games.filter(game => game.homePoints === null || game.awayPoints === null).length > 0;

    const removeUnfinishedGamesSection = hasUnfinishedGames ? <div style={{marginBottom: '1em'}}>
        <Button fullWidth variant="contained" color="error" disabled={isPending} onClick={() => dispatch(undraw([]))}>
            Verwijder ongespeelde wedstrijden
        </Button>
    </div> : null;

    return <div>
        <div style={{marginTop: '1em', marginBottom: '1em'}}>
            <Button fullWidth variant="contained" color="success" disabled={isPending} onClick={() => dispatch(draw([]))}>
                Genereer wedstrijden
            </Button>
        </div>
        {removeUnfinishedGamesSection}
        <GamesList />
    </div>
}

export default GameSection;
